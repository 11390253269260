import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import axios from '../AxiosInstance';
import swal from 'sweetalert2';
//this constant is in the backend for validate the response 
function ResetPassword() {
    const [passUser, setPassUser] = useState('');
    const [passConfir, setPassConfir] = useState('');
    const [validateToken, setValidateToken] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        

        const token = searchParams.get('Token');

        let datatoken={
            token: token
        }
        axios.post('webapi/tokenValidate', datatoken, {
            headers : {
                'acces-token': token
            }
        })
            .then((resp)=>{
               if(resp.data.message==='ok'){
                setValidateToken(true);
               }
               else{
                swal.fire({ title: 'Link inválido', text: 'Por favor vuelva a intentar', icon: 'error' });
                setValidateToken(false);
               }
            })
    },[validateToken]);

    function onClick_Pass(e) {
        e.preventDefault();

        const token = searchParams.get('Token');

        let dataUser = {
            password: passUser.trim(),
            contrasena: passConfir.trim()
        };

        if(dataUser.password.length === 0){
            swal.fire('Error', 'Contraseña Indefinida', 'error');
            return;
        }

        console.log(token);

        if(dataUser.password === dataUser.contrasena){
            axios.put('/webapi/confirmarContra', dataUser, {
                headers : {
                    'acces-token': token
                }
            })
            .then((resp) => {
               
                    if (resp.data.message==='ok') {
                        swal.fire({ title: 'Contraseña modificada', text: 'Pulsar en Ok para regresar al login', icon: 'info' })
                        .then(function() {
                            navigate('/login');
                        });
                    } else {
                        swal.fire('Error', resp.data.mensaje, 'error');
                    }
            })
            .catch((err) => {
                console.log(err);
                swal.fire('Error', err, 'error');

            }); 
        }else{ swal.fire({ title: 'La contraseña no coincide', text: 'Por favor validar que sean iguales', icon: 'error' });}
    }
    function onClick_LoginReturn(){
        navigate('/login');
    }
    return (

        <div className="page-holder align-items-center py-4 bg-gray-100 vh-100">
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className="col-lg-6 px-lg-4">
                        <div className="card">
                            <div className="card-header px-lg-12">
                                <div className="card-heading text-primary text-center h2">Subastemos</div>
                            </div>
                            {validateToken ?
                            <div className="card-body p-lg-12">
                                <h3 className="mb-4 text-center">Cambio de Contraseña</h3>
                                <div className='row justify-content-md-center'>
                                    <div className='col-md-auto'>
                                        <div id='signInDiv'></div>
                                    </div>
                                </div>
                                <br /><br />
                                <div className='row justify-content-md-center'>
                                    <form onSubmit={e => onClick_Pass(e)} >
                                        <div className="form-floating mb-3">
                                            <input className="form-control" id="floatingInput" type="password" placeholder="name@example.com"
                                                onChange={e => setPassUser(e.target.value)} value={passUser} />
                                            <label htmlFor="floatingInput">Nueva contraseña</label>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input className="form-control" id="floatingPassword" type="password" placeholder="Password"
                                                onChange={e => setPassConfir(e.target.value)} value={passConfir} />
                                            <label htmlFor="floatingPassword">Confirmar contraseña</label>
                                        </div>

                                        <div className='row justify-content-md-center'>
                                            <button className="btn btn-primary btn-lg col-md-auto" type="submit"  >Confirmar</button>
                                        </div>

                                    </form>
                                </div>
                            </div>:
                            <div>
                            <br/>
                            <div className='row justify-content-md-center'>
                                <button className="col-md-6 btn btn-primary" onClick={onClick_LoginReturn}>
                                    <i className="fa fa-arrow-left"></i> Regresar al login 
                                </button>
                            </div>
                            <br/>
                            </div>
                        }
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )

}
export default ResetPassword;

import React, { Fragment, useEffect } from "react";

import { Route, Routes, useNavigate } from "react-router-dom";
import App from './components/app';
import axios from './AxiosInstance';
import { useState } from "react";
import { locale, addLocale} from 'primereact/api';
import { ProgressBar } from 'primereact/progressbar';
import './components/loadings.css';

//import CardDesign from "./components/Components/CardDesign/CardDesign";
//import {MENUITEMS} from './layouts/SideBar/SideMenu';

const Switcherlayout = React.lazy(()=>import("./components/switcherlayout"));
const Welcome = React.lazy(()=>import("./pages/Welcome"));

//Dashboard
const Dashboard = React.lazy(()=> import("./components/Dashboard/Dashboard"));

const Empresas = React.lazy(()=>import("./pages/Administracion/Empresas"));

const Modulos = React.lazy(()=>import("./pages/Seguridad/Modulos"));
const Roles = React.lazy(()=>import("./pages/Seguridad/Roles"));
//const Usuarios = React.lazy(()=>import("./pages/Seguridad/Usuarios/Usuarios"));
const Usuarios = React.lazy(()=>import("./pages/Seguridad/Usuarios/UsuariosScreen"));

const Controls_Factory = React.lazy(()=>import("./pages/Administracion/Controls_Factory/Controls_Factory"))

//Inventario Vehiculos
const DatosGeneralesMain = React.lazy(()=>import("./pages/InventarioVehiculos/DatosGenerales/DatosGeneralesMain"));
const DatosMecanicos = React.lazy(()=>import("./pages/InventarioVehiculos/DatosMecanicos"));
const DatosEsteticos = React.lazy(()=>import("./pages/InventarioVehiculos/DatosEsteticos"));
const CamposObligatoriosMain = React.lazy(()=>import("./pages/InventarioVehiculos/CamposObligatorios/CamposObligotoriosMain"));
const VehiculoScreen = React.lazy(()=>import("./pages/Vehiculos/MainVehiculos"));
const OrdenamientoControles = React.lazy(()=>import("./pages/Administracion/Controls_Factory/Ordenamientos"));
const InformacionVehiculo = React.lazy(()=>import("./pages/Vehiculos/ScreenInformacionVehiculo"));
const MainSubastasScreen = React.lazy(()=>import("./pages/Subastas/MainSubastas"));

const Menu_Adapter = {  
      "Usuarios" : {
        Component : Usuarios,
      },
      "Roles" : {
        Component : Roles,
      },
      "Módulos":{
        Component : Modulos
      },
      "Empresas" : {
        Component: Empresas
      },
      "Dashboard" : {
        Component: Dashboard
      },
      "Formularios Dinámicos" : {
        Component : Controls_Factory
      },
      "Datos Generales" : {
        Component : DatosGeneralesMain
      },
      "Datos Mecánicos" : {
        Component : DatosMecanicos
      },
      "Datos Estéticos" : {
        Component : DatosEsteticos
      },
      "Campos Obligatorios" : {
        Component : CamposObligatoriosMain
      },
      "Vehículos" : {
        Component : VehiculoScreen
      },
      "Orden Controles" : {
        Component : OrdenamientoControles
      },
      "Información Vehiculo" : {
        Component : InformacionVehiculo
      },
      "Subastas" : {
        Component: MainSubastasScreen
      },
      "Perfil" : {
        Component : Welcome
      }
  }



axios.defaults.headers.common['acces-token'] = localStorage.getItem('token');

addLocale('es', {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
  dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
  dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
  monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
  monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
  today: 'Hoy',
  matchAll: 'Match con todos',
  matchAny: 'Match alguno',
  startsWith : 'Empieza con',
  contains : 'Contiene',
  notContains: 'No contiene',
  endsWith : 'Termina con',
  equals : 'Igual a',
  notEquals : 'No igual',
  dateIs: 'Fecha es',
  addRule : 'Agregar Regla',
  removeRule: 'Quitar Regla',
  apply: 'Aplicar',
  clear: 'Limpiar'
});

locale('es');

const Root = ({empresa, empresas, establecimiento, setEstablecimiento, hasManyEstablecimientos}) => {

    const [menus, setMenus] = useState([]);
    const [opciones, setOpciones] = useState([]);
    const navigate = useNavigate();
    
  useEffect(()=>{
    getMenu();
  }, [])

  useEffect(()=>{
    if(localStorage.getItem('last_page_visited') && opciones.length){

      const info_auto_path = localStorage.getItem('last_page_visited');
      localStorage.removeItem('last_page_visited')

      navigate(info_auto_path, { state: {alreadyRenderized: true}});

    }
  }, [opciones])
  
  const onLogout = () => { 
    localStorage.clear();
    axios.get('/webapi/logout').then((resp)=>{
        window.location = '/';
    });  
  }


  const getMenu = ()=>{
      axios.get('/webapi/getmenus_session').then((res)=>{     

          if(res.data.status === 401){
              onLogout();
          }else{

              let respMenus = res.data.menus || [];
             
              setMenus(respMenus);   

              // const allOps = res.data.menus[0].Items.reduce((prev, curr)=>{
              //   return [...prev, ...curr.children]
              //  }, []);

              // setOpciones(allOps);

              let options_for_router = res.data.options_for_router || [];

              setOpciones(options_for_router);
          }
      })
  }


  return (
    <Fragment>
        <Routes>
          <Route
            path={`/`}
            element={<App menus={menus} 
            establecimiento={establecimiento} 
            setEstablecimiento={setEstablecimiento} 
            empresa={empresa}
            empresas={empresas} 
            hasManyEstablecimientos={hasManyEstablecimientos}/>}
          >
            <Route index element={<Dashboard/>} />
               {opciones.map((option)=>{
                      
                      const PageComponent = Menu_Adapter[option.title]?.Component;

                      if(PageComponent){
                        return <Route
                                    key={option.path}
                                    path={option.path}
                                    element={<PageComponent menus={menus} option={option} establecimiento={establecimiento} empresa={empresa}/>}
                                />;
                      }else{
                        return null;
                      }
                  })
              }
              <Route path="*" element={ <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}></Route>
              <Route  key={"/user/perfil"} path={"/user/perfil"} element={<Welcome empresas={empresas}/>}></Route>
          </Route>
          <Route
            path={`${process.env.PUBLIC_URL}/pages/themeStyle`}
            element={<Switcherlayout />}
          />
        </Routes>
    </Fragment>
  );
};

export default Root;
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";  
//import Root from './MainHome';
import App from "./App";
import { BrowserRouter } from "react-router-dom";
//import EmpresasSelector from "./EmpresasSelector";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<BrowserRouter>
                <App />
            </BrowserRouter>
            );

import React from "react";
import './App.css';
//import { useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
//import DataTableExtensions from "react-data-table-component-extensions";
import { Button } from "react-bootstrap";
//import "react-data-table-component-extensions/dist/index.css";
import axios from './AxiosInstance';
import { Navigate } from "react-router-dom";


axios.defaults.headers.common['acces-token'] = localStorage.getItem('token');


function EmpresasSelector({onSelectEstablecimiento, empresas, authorized}) {

  const columns = [
    {
      name: "NOMBRE COMERCIAL",
      selector: (row) => [row.nombrecomercial],
      sortable: true,
    },
  ];

  const data = empresas;

  const Divalertempresas = () => {
    return (<div className="alert alert-warning text-center" role="alert">No tiene empresas asignadas</div>);
  }


  const onLogout = () => {
    localStorage.clear();
    axios.get('/webapi/logout').then((resp) => {
      window.location = '/';
    });

  }

  const ExpandedComponent = ({ data }) => {

    return <DataTable
      columns={[
        { name: "Establecimiento", selector: (row) => row.nombre_establecimiento },
        {
          button: true,
          cell: (row) => {
            return <Button
              size="sm"
              onClick={()=>onSelectEstablecimiento(data, row)}
            ><i className="fa fa-check"></i> Seleccionar</Button>
          }
        },
      ]}
      data={data.establecimientos}
    />
  };

  if(localStorage.getItem('token') && authorized){

      return (

        <div className='container-fluid' style={{
          height: '100vh',
          //backgroundRepeat: 'no-repeat',
          //backgroundSize: 'cover',
          //backgroundPosition: 'center',
          //backgroundImage: 'url(https://images.vexels.com/media/users/3/100549/raw/3bfb08129f0e0d4546e333f636c78539-vector-de-fondo-de-diseno-abstracto.png)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: data.length < 2 ? 'center' : 'flex-start',
          paddingTop: 10
        }}>
          <div className='row justify-content-center'>
            <div className='col-md-6'>
              <div className="card">
                <div className='card-header' style={{backgroundColor: 'rgb(98,89,202)', color: 'white', justifyContent: 'center'}}>
                  <h4 className='h4'>SELECCIÓN DE ESTABLECIMIENTOS GENERALES</h4>
                </div>
                {data.length === 0 && <Divalertempresas />}

                <div className='card-body'>
                  <div className="table">

                    <DataTable
                      columns={columns}
                      data={data}
                      defaultSortField="idempresa"
                      defaultSortAsc={false}
                      center={true}
                      expandableRows
                      expandableRowsComponent={ExpandedComponent}
                    />

                  </div>
                  <br/>
                  <div className='row justify-content-start'>
                  <div className='col-md-2'>
                    <br/>
                    <Button onClick={onLogout} size="lg" variant="danger"><i className="fa fa-arrow-left"></i> Salir</Button>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
  else{
    return <Navigate to="/login"/>
  }
}




export default EmpresasSelector;

import React, {useState, useEffect, useRef} from "react";
import { Button, Card, Modal, Row, Col, Container } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Button as PrimeButton } from "primereact/button";
import io from 'socket.io-client';
import swal from "sweetalert2";
import axios from './AxiosInstance';
import fondo from './assets/images/users/fondo-derecho2.svg'
import logo from './assets/images/users/logo-subastemos.png'
import './login.css';


//import * as custompagesswitcherdata from "./data/Switcher/Custompagesswitcherdata"

const STATUS_LOGIN = {error: -1, none : 0, newregister: 1, pending: 2, isvalidated:3 }

export default function Login() {

    const [nameUser, setNameUser] = useState('');
    const [passUser, setPassUser] = useState('');
    const [typePass, setTypePass] = useState('password');
    const [fieldsLogin, setFieldsLogin] = useState(true);
    const [email, setEmailUser] = useState('');
    const [textRegisterAndActivation, setTextRegisterAndActivation] = useState('');
    const [titleRegisterAndActivation, setTitleRegisterAndActivation] = useState('');
    const [loadingForm, setLoadingForm] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const IOClient = useRef(null);

    function onClick_Login(e){
        e.preventDefault();        

        setLoadingForm(true);
        
        let dataUser = { 
                username : nameUser, 
                password : passUser.trim()  
            };

         

        axios.post('/webapi/login', dataUser)
            .then((resp) => {
                if (resp.data.estatus === 'ok') {

                    configLogin(resp.data.usuario);
                    
                }else{
                    swal.fire('Error', resp.data.mensaje, 'error');
                }

                setLoadingForm(false);
            })
            .catch((err)=>{
                console.log(err);
                swal.fire('Info', err.toString(), 'error');
                setLoadingForm(false);
            });
    }

    useEffect(()=>{ 

        if(IOClient.current === null){


            const dominio = process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'https://subastas-dev.ue.r.appspot.com';

            // IOClient.current = io(dominio, {
            //     transports: ['websocket'],
            //     query : {
            //         token: localStorage.getItem('token')
            //     }
            // }); 

            IOClient.current = io(dominio, {
                query : {
                    token: localStorage.getItem('token')
                }
            }); 
        }
    
        return ()=>{
            IOClient.current?.close();
        }
    
      }, [])
    
    
      const sendToServer = (data)=>{
        if(IOClient.current){
          const { current : SocketIO } = IOClient;
          SocketIO.emit('add-user', data);
        }
    }


    //Set the session storage variables
    function configLogin(dataUser){

        console.log(dataUser.rol);
        
        localStorage.setItem('id' , dataUser.idusuario);
        localStorage.setItem('nombre_completo' , dataUser.nombre_completo);
        localStorage.setItem('token' , dataUser.token);
        localStorage.setItem('email' , dataUser.email);
        localStorage.setItem('path_foto', dataUser.path_foto);
        localStorage.setItem('vencimiento_token', dataUser.vencimiento_token);
        localStorage.setItem('rol', dataUser.rol);

                                                
        axios.defaults.headers.common['acces-token'] = dataUser.token;

        window.location ='/';
    }
    

    useEffect(()=>{          
        //Send Token for validate in backend    
        const handleCallBackResponse = (response)=>{
            console.log(response);
            axios.post('/webapi/login_google', {credential_google : response.credential})
                        .then((res)=>{
                            switch (res.data.usuario.statusLogin) {
                                case STATUS_LOGIN.isvalidated:                                   
                                    configLogin(res.data.usuario.user);
                                    break;
                                case STATUS_LOGIN.newregister:
                                    //swal.fire({title: 'REGISTRO EXITOSO', text: 'El usuario ha sido registrado exitosamente, espera a que un administrador active su usuario. GRACIAS', icon: 'info'});    
                                    setTextRegisterAndActivation('El usuario ha sido registrado exitosamente, espera a que un administrador active su usuario. GRACIAS');
                                    setTitleRegisterAndActivation('REGISTRO EXITOSO')
                                    setFieldsLogin(false);
                                    sendToServer(res.data.usuario);
                                    break;                            
                                case STATUS_LOGIN.pending:
                                    setTextRegisterAndActivation('El usuario esta pendiente de activación');
                                    setTitleRegisterAndActivation('ACTIVACIÓN PENDIENTE')
                                    setFieldsLogin(false);
                                    break;                            
                                case STATUS_LOGIN.error:
                                    swal.fire('Error', res.data.usuario.message, 'error'); 
                                    break;                        
                                default:
                                    break;
                            }
                        }).catch((error) => {
                            console.log(error);
                        });
    
        } 

        /* global google */
         //client_id : "865099645390-f48e0391id8se7duhsmqhrk791qm3m30.apps.googleusercontent.com",
        google.accounts.id.initialize({
            client_id : "263903528316-e0b3of8uguieqge9vpt710otmupdei0e.apps.googleusercontent.com",
            callback: handleCallBackResponse,
        });
    
        google.accounts.id.renderButton(
            document.getElementById("signInDiv"),
            { 
                theme : "filled_blue" , 
                size : "large", 
                shape : "circle"
            }
        ); 
      
        //google.accounts.id.prompt();
    }, [fieldsLogin]);

  

     function onClick_Rest(e){
        let dataUserRest = { 
                email : email
            };
        axios.post('/webapi/res', dataUserRest)
            .then((resp) => {
                
                if (resp.data.message === 'ok') {
                    swal.fire({ title: 'Correo Enviado', text: 'Le hemos enviado un correo con su contraseña nueva.', icon: 'info'});
                    
                }else if(resp.data.message === 'by_google'){

                    swal.fire({ title: 'Advertencia', text: resp.data.responsedata, icon: 'error'});
                    
                }else{
                    swal.fire({ title: 'Correo Inválido', text: 'El correo ingresado no es correcto, por favor revisar y volver a intentar', icon: 'error'});
                }
                
            })
            .catch((err)=>{
                console.log(err);
                swal.fire('Error', err, 'error');
            });
        }

        const visualizarPassword = ()=>{

            
            // if(passUser.trim().length > 0){
                    if(typePass === 'password'){
                        setTypePass('text');
                    }else{
                        setTypePass('password');
                    }
             //}

        }
 
  return (
     <div className="main_container_login">
         <Container fluid>
                <Row>
                        <Col sm={12} md={6} lg={4} >
                            <div className="main_form_login">
                                 <div className="container_logo">
                                                <img src={logo} className="logo_dimensions"/>
                                 </div>
                                 <div style={{backgroundColor: 'white', height: '72vh'}}>
                                    <Row style={{marginTop: 20, marginLeft: 10, marginRight: 10, marginBottom: 10}}>
                                        <Col>
                                     {/* <Card>
                                        <Card.Body> */}
                                        <div className="text-center"><label>V1.11.10</label></div>
                                         <Row style={{justifyContent: 'center'}}>
                                            <Col md={12}>
                                                  <h4 style={{textAlign: 'center', fontWeight: 'bold'}}>Bienvenidos al Sistema</h4>
                                            </Col>
                                         </Row>
                                         <br/>
                                        {fieldsLogin && <div style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'center'
                                                                    }}>                                    
                                                                <div id='signInDiv'></div>                                                                          
                                        </div>}
                                        {fieldsLogin && <>
                                        <div className="space_between_form"></div>
                                        <h6 className="container_text_border_line">
                                            <span className="line_around_text"><strong>Ó ingrese correo y contraseña</strong>
                                            </span>
                                        </h6>
                                        </>} 
                                         <div className="space_between_form"></div>
                                        { fieldsLogin ? 
                                            <form onSubmit={e => onClick_Login(e)}>
                                            <div className="wrap-input100 validate-input">
                                                 <InputGroup className="mb-3">
                                                            <InputGroup.Text id="basic-addon1" onClick={visualizarPassword}><i className="fa fa-user"></i></InputGroup.Text>
                                                            <Form.Control
                                                            type="email" 
                                                            autoComplete="on"
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                            placeholder="Nombre@dominio.com"
                                                            onChange={e => setNameUser(e.target.value)} 
                                                            value={nameUser} 
                                                            />
                                                        </InputGroup>
                                            </div>
                                            <div className="wrap-input100 validate-input">
                                                    <InputGroup className="mb-3">
                                                            <InputGroup.Text id="basic-addon1" onClick={visualizarPassword}>
                                                                {
                                                                     <i  className={`fa fa-${passUser.length > 0 ? 'eye': 'lock'}`}></i>
                                                                }
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                            type={typePass} 
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                            placeholder="Contraseña"
                                                            onChange={e => setPassUser(e.target.value)} 
                                                            value={passUser} 
                                                            />
                                                    </InputGroup>
                                              
                                            </div>
                                            <div className="text-end pt-1">
                                                <p className="mb-0">
                                                    <Modal
                                                            show={showDialog}
                                                            backdrop="static"
                                                            keyboard={false}
                                                        >
                                                        <Modal.Header>
                                                        <Modal.Title>Cambiar contraseña</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <label>Ingresar el correo electrónico:</label>
                                                            <input className="form-control" id="floatingInput" type="email" placeholder="Nombre@dominio.com"
                                                            onChange={e => setEmailUser(e.target.value)} 
                                                            value={email}
                                                            />
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                        <Button variant="secondary" onClick={()=>setShowDialog(false)}>
                                                            Cerrar
                                                        </Button>
                                                        <Button variant="primary" onClick={e=> onClick_Rest(e)}>Enviar Email</Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                
                                                </p>
                                            </div>
                                            <br/>
                                            <div style={{display: 'flex',flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                                    {/* <button className="btn btn-primary btn-lg col-md-auto" type="submit"  >Iniciar Sesión</button> */}
                                                     <PrimeButton label="Iniciar Sesión" type="submit" loading={loadingForm} />
                                                    <br/>
                                                    <button
                                                            onClick={(e) =>{e.preventDefault(); setShowDialog(true) }}
                                                            className="text-primary ms-1"
                                                        >
                                                       ¿Olvidó su contraseña?
                                                   </button> 
                                                   <br/>
                                                   
                                            </div>
                                            <div className="space_after_form"></div>
                                            </form> : <div className='card-body p-lg-12'>
                                                        <h3 className="mb-4 text-center">{titleRegisterAndActivation}</h3>
                                                        <div className='row justify-content-center'>
                                                            <label className='col-md-8'>
                                                                {textRegisterAndActivation}
                                                            </label>
                                                        </div>
                                                        <br />
                                                        <div className='row justify-content-md-center'>
                                                            <button className="col-md-6 btn btn-primary" onClick={ ()=> setFieldsLogin(true)}>
                                                                <i className="fa fa-arrow-left"></i>Regresar
                                                            </button>
                                                        </div>
                                                    </div>    }
                                                   
                                                <br/>
                                                <br/>
                                        {/* </Card.Body>
                                        </Card> */}
                                        </Col>
                                        </Row>
                                </div>
                                {/* <div style={{height: 'auto', fontSize: 'small', color: 'white', display: 'flex', justifyContent: 'center'}}>
                                     <strong className="frase_final">La mejor experiencia de Subastas en México</strong>
                                </div> */}
                           </div>
                        </Col>
                        <Col sm={12} md={6} lg={8} className="d-none d-md-block">
                                <img src={fondo} className="right_background_image"
                                />
                        </Col>
                </Row>
         </Container>
     </div>
  );
}

import {useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation} from 'react-router-dom';
import Root from './MainHome';
import EmpresasSelector from './EmpresasSelector';
import Login from './Login';
import PasswordRecovery from './pages/ResetPassword';
import axios from './AxiosInstance';
import {Loaderimg} from './GeneralLoading';

function App() {


  const [establecimiento, setEstablecimiento] = useState(undefined);
  const [empresa, setEmpresa] = useState(undefined);
  const [empresas, setEmpresas] = useState([]);
  const [authorized, setAuthorized] = useState(false);
  const [hasManyEstablecimientos, setHasManyEstablecimientos] = useState(false);

  const navigate = useNavigate();
  const locationHook = useLocation();


  if(locationHook.pathname.startsWith('/cars/info_vehicle')){
    if(!locationHook.state?.alreadyRenderized){
      localStorage.setItem('last_page_visited', locationHook.pathname);
    }
  }
      



  const getEmpresas = async ()=>{

    const resp = await axios.get('/apiseguridad/getEmpresasAndEstablecimientos');
  
    if(resp.data.message === 'ok'){
        const empresasResponse = resp.data.responsedata;
        setEmpresas(empresasResponse);
    }
  }
  


  const onSelectEstablecimiento = async (empresaParam, establecimientoParam, fromMainSelector=true)=>{

         const resp = await axios.post('/apiseguridad/setEstablecimiento', {
             establecimiento : establecimientoParam,
             empresa : empresaParam
         });

         if(resp.data.message === 'ok'){
           
           setEmpresa(empresaParam);
           setEstablecimiento(establecimientoParam);
           if(fromMainSelector){
             navigate('/');
           }
         }
  }



  useEffect(()=>{
  
               if(localStorage.getItem('token')){
                      getEmpresas();
      
                      if(establecimiento === undefined){
                      
                        goToQueryBackendMyEstablecimiento().then((resp)=>{

                          let selectedEmpresa = null;
                          let selectedEstablecimiento = null;
      
                          if (resp.empresa && resp.establecimiento){
      
                            selectedEmpresa = resp.empresa;
                            selectedEstablecimiento = resp.establecimiento;
      
                          }
                          
                          setHasManyEstablecimientos(resp.hasManyEstablecimientos);
                          setEmpresa(selectedEmpresa);
                          setEstablecimiento(selectedEstablecimiento);
      
                          if(resp.status !== 401){
                            setAuthorized(true);
                          }
      
                        })
                      }
               }
  }, []);



  if (localStorage.getItem('token')){
 
          if(establecimiento === undefined){
            return <Loaderimg/>
          }
          
          return (    
            <div>
              
                    {(              
                            establecimiento 
                            ? <Root empresa={empresa}  //Root es tambien un Routes que tiene rutas dentro.
                                empresas={empresas} 
                                establecimiento={establecimiento} 
                                hasManyEstablecimientos={hasManyEstablecimientos}
                                setEstablecimiento={onSelectEstablecimiento} 
                              /> 
                            : <Routes>
                                  <Route path="/" element={<Navigate to="/empresas-selector"/>}/>
                                  <Route path="/empresas-selector" element={<EmpresasSelector onSelectEstablecimiento={onSelectEstablecimiento} 
                                    empresas={empresas}
                                    authorized={authorized}
                                  />} />
                                 <Route path="/login" element={<Login authorized={authorized}/>}/>
                                 <Route path="*" element={<Navigate to="/empresas-selector"/>}/>
                              </Routes>
                          )
                    }
            </div>
          );
    }else{
      return <Routes>
           <Route path="/" element={<Navigate to="/login"/>}/>
           <Route path="/login" element={<Login authorized={authorized}/>}/>
           <Route path="/recoverypass" element={<PasswordRecovery/>} />
           <Route path="*" element={<Navigate to="/login"/>}/>
      </Routes>
    }

    //Y en este ultimo Routes, pueden ir las rutas de ForgotPassword etc.


}

const goToQueryBackendMyEstablecimiento= async ()=>{

  const resp = await axios.get('/apiseguridad/getEstablecimientoLogued');

  const establecimiento = resp.data.establecimiento;
  const empresa = resp.data.empresa;
  const hasManyEstablecimientos = resp.data.hasManyEstablecimientos;


    return {
      hasManyEstablecimientos,
      establecimiento,
      empresa,
      status : resp.data.status === 401 ? 401 : 200
  }
}

export default App;

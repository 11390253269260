import axios from 'axios';

// PARA PRUEBAS baseURL es la ruta de mi backend completa http://localhost:3001, pero para produccion al momento de hacer el build 
// se deja en blanco para que sea relativa al dominio de node

const Axios = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : '',
    withCredentials: true
});

export default Axios;
import './GeneralLoading.css';
export const LoadingCircle = ()=><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
export const Loaderimg = () => {
  return (
    <div id="global-loader">
      <img
        src={require("./assets/images/loader.svg").default}
        className="loader-img"
        alt="Loader"
      />
    </div>
  );
};


import React, { Fragment } from "react";
import Header from "../layouts/Header/Header";
import Sidebar from "../layouts/SideBar/SideBar";
import Footer from "../layouts/Footer/Footer";
import Switcher from "../layouts/Switcher/Switcher";
import RightSidebar from "../layouts/RightSidebar/RightSidebar";
import * as Switcherdata from "../data/Switcher/Switcherdata";
import { Outlet } from "react-router-dom";
import { ProgressBar } from 'primereact/progressbar';
import TabToTop from "../layouts/TabToTop/TabToTop";
import './loadings.css';


export default function App({menus, establecimiento, setEstablecimiento, empresas, empresa, hasManyEstablecimientos}) {
  return (
    <Fragment>
      <div className="horizontalMenucontainer">
        <TabToTop />
        <div className="page">
          <div className="page-main">
            <Header establecimiento={establecimiento}
                    setEstablecimiento={setEstablecimiento}
                    empresas={empresas}
                    empresa={empresa}
                    hasManyEstablecimientos={hasManyEstablecimientos}
            />
            <Sidebar MENUITEMS_PARAM={menus}/>
            <div className="main-content app-content ">
              <div className="side-app">
                <div
                  className="main-container container-fluid"
                  onClick={() => {
                    Switcherdata.responsiveSidebarclose();
                    Switcherdata.Horizontalmenudefultclose();
                  }}
                >
                   <React.Suspense fallback={<ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}>
                       <Outlet />
                   </React.Suspense>
                </div>
              </div>
            </div>
          </div>
          <RightSidebar />
          <Switcher />
          <Footer />
        </div>
      </div>
    </Fragment>
  );
}

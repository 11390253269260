import React from 'react';
import {Link} from "react-router-dom"
import{Row,Col} from "react-bootstrap"
const Footer = () => {
return(
<div className="footer">
			<div className="container">
				<Row className="align-items-center flex-row-reverse">
					<Col className="text-center" sm={11} md={11} lg={11}>
						 Powered by <a href='https://www.ipetik.com' target="_blank">IPETIK Solutions</a>
					</Col>
				</Row>
			</div>
		</div>

);
}
  
export default Footer;
